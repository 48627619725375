.federationContainer {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 20px auto;
  font-family: "Arial", sans-serif;
}

.federationContainer strong {
  font-size: 1.5rem;
  color: #333;
  display: block;
  margin-bottom: 20px;
}

.federationContainer p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  border: 2px dashed #f71a1a;
  border-radius: 10px;
  padding: 15px;
}

.fedImgSection {
  text-align: center;
  margin-right: 20px;
}

.fedJudoImage {
  max-width: 350px;
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
