.top-events-container {
  margin: 70px;
  min-height: 450px;
}
.cardTopEvents {
  margin-bottom: 40px;
  height: 500px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.cardRegEvents {
  height: 150px;
  margin-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}
.cardRegEvents:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.cardTopEvents:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.cardTopEvents:hover .image {
  opacity: 0.9;
}
.image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: opacity 0.3s;
}

.eventstitle {
  font-size: 18px;
  font-weight: bold;
  color: #4587e9;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.descEvents {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  -webkit-text-stroke: 0.6px #7c7c7c;
}

.dateEvent {
  margin: 0;
  font-weight: bold;
  color: #d81111;
  font-size: 14px;
}

.dateEvent span {
  color: #021a88;
  margin-right: 10px;
  justify-content: space-around;
}

.datePicker {
  height: 40px;
  max-width: 250px;
  color: #4587e9;
  border-color: #0c10eb;
  margin-top: 25px;
  width: 100%;
  cursor: pointer;
}

.ant-input {
  height: 40px;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  height: 40px;
}
.ant-card > .cardTopEvents {
  height: 500px;
}

.input {
  height: 40px;
  max-width: 250px;
  color: #4587e9;
  border-color: #0c10eb;
  margin-top: 25px;
  width: 100%;
  margin-right: 20px;
}

.selectSex {
  height: 40px;
  max-width: 250px;
  color: #4587e9;
  border-color: #0c10eb;
  margin-top: 25px;
  width: 100%;
  margin-right: 20px;
  border-radius: 7px;
  cursor: pointer;
}

.datepickerEvents-container {
  border-radius: 10px;
  height: 100px;
  background-color: #021a88;
  margin: 40px 70px 70px 70px;
  padding: 10px;
  display: block;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .top-events-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .dateEvent {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .top-events-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 949px) {
  .input {
    margin-top: 10px;
  }

  .selectSex {
    margin-top: 10px;
  }

  .datePicker {
    margin-top: 10px;
  }
}

@media (max-width: 722px) {
  .dateEvent {
    font-size: 12px;
  }
}

@media (max-width: 679px) {
  .datepickerEvents-container {
    height: 150px;
  }
}

@media (max-width: 600px) {
  .top-events-container {
    grid-template-columns: 1fr;
  }
  .card {
    width: 100%;
  }
  .dateEvent {
    font-size: 14px;
  }
  .cardRegEvents {
    height: 200px;
  }
}
