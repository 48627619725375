.slider-container {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative; 
  z-index: 1; 
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImage {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  /* max-height: 850px; */
}

.slider-content {
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .3));
  height: 100%;
  justify-content: space-between;
  left: 0;
  padding: 8rem 7% 2rem;
  position: absolute;
  top: 0;
  width: 100%;
  color: aliceblue;
  z-index: 2; 
  font-family: Arial, Helvetica;
}
.slider-desc {
  max-width: 90%; 
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal; 
  font-family: Arial;
  font-size: 14px;
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical; 
  overflow: hidden; 
  text-overflow: ellipsis;
  font-weight: 300;
  letter-spacing: 1px;
}

.button {
border-radius: 5px;
margin-top: 10px;
border: none;
color: white;
width: 180px;
height: 50px; 
font-size: 16px;
background: #d81111;
display: inline-block;
letter-spacing: 1px;
transition: all .5s ease-in-out;
color: white;
cursor: pointer;
}

.button:hover{
  background-color: #4587e9;
}


@media (max-width: 480px) {
  .slider-content{
    margin-top: -60px;
  }
  .swiper-button-prev{
    width: 10px;
    height: 10px;
  }
}