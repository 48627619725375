.footer {
  position: absolute;
  width: 100%;
  background-color: #222;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-section {
  max-width: 300px;
  margin-bottom: 20px;
}

.footer h3 {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.footer p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.footer .social-links a {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}

.footer .social-links a i {
  font-size: 18px;
}

.footer-section.links ul {
  list-style-type: none;
  padding: 0;
}

.footer-section.links ul li {
  margin-bottom: 10px;
}

.footer-section.links ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-section.links ul li a:hover {
  text-decoration: underline;
}

.footer-section.contact ul {
  list-style-type: none;
  padding: 0;
}

.footer-section.contact ul li {
  margin-bottom: 10px;
}

.footer-section.contact ul li i {
  margin-right: 10px;
}

.footer-bottom {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #444;
  padding-top: 20px;
}

.footer-bottom p {
  margin: 0;
}

.footer-bottom a {
  color: #f00;
  text-decoration: none;
}
