.management-container {
 min-height: 70vh;
 display: flex;
 width: 100%;
 justify-content: center;
 flex-direction: row-reverse;
}

.role-list {
 list-style-type: none;
 padding: 0;
 width: 75%;
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-content: center;
 align-items: center;
}

.role-item {
 margin-bottom: 20px;
 width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-content: center;
 align-items: center;
}

.role-title {
 font-weight: bold;
 font-size: 1.2em;
 margin-bottom: 10px;
 color: #4587e9;
}

.member-list {
 list-style-type: none;
 padding: 0;
 display: flex;
 flex-wrap: wrap;
 gap: 10px;
 width: 100%;
 justify-content: center;
 align-items: center;
}

.member-item {
 display: flex;
 align-items: center;
 width: 100%;
 max-width: 300px;
 background: #f8f8f8;
 padding: 10px;
 border-radius: 8px;
 box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.member-item:hover {
 transform: translateY(-2px);
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 transition: transform 0.3s, box-shadow 0.3s;
}

.member-image {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 margin-right: 10px;
}

.member-details {
 display: flex;
 flex-direction: column;
}

.member-name {
 font-weight: bold;
}

.member-description {
 color: #888;
 font-style: italic;
}
