.usersContainer {
  background-color: transparent;
  padding: 50px;
  background-size: cover;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
    url("../../../assets/wp2663019.webp");
  background-attachment: fixed;
  background-position: 50%;
}

.titleUsers {
  margin-top: -15px;
  background-color: #b5d1f7;
  margin-bottom: 2%;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  color: #4587e9;
  width: -webkit-max-content;
  width: -moz-max-content;
  /* width: max-content; */
  max-width: 400px;
  border-radius: 6px;
  font-size: 24px;
  font-family: sans-serif;
}

.titleUsers:hover {
  transform: translateY(-2px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.usersCard {
  overflow: hidden;
  position: relative;
}

.usersImg {
  width: 100%;
  height: 350px;
  border-bottom: 3px solid red;
  object-fit: cover;
}

.userContent {
  text-align: center;
  height: auto;
  justify-content: flex-end;
  left: 0;
  padding: 1rem;
  position: absolute;
  bottom: 7px;
  width: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}

.userContent p {
  margin: 5px;
}

.swiper-pagination-bullets {
  display: none;
}
