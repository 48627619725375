.news-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 70px;
  text-align: center;
  gap: 20px;
}

.card {
  margin-bottom: 40px;
  height: 500px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.image {
  width: 100%;
  height: 330px;
  object-fit: cover;
  transition: opacity 0.3s;
}
.card:hover .image {
  opacity: 0.9;
}
.no-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #f0f0f0;
  color: #888;
  font-size: 14px;
  text-align: center;
}

.newstitle {
  font-size: 16px;
  font-weight: bold;
  color: #4587e9;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  -webkit-text-stroke: 0.6px #7c7c7c;
}

.date {
  margin: 0;
  font-weight: bold;
  color: #d81111;
}

.datePicker {
  height: 40px;
  max-width: 250px;
  color: #4587e9;
  border-color: #0c10eb;
  margin-top: 25px;
  width: 100%;
  cursor: pointer;
}

.ant-input {
  height: 40px;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  height: 40px;
}

.input {
  height: 40px;
  max-width: 250px;
  color: #4587e9;
  border-color: #0c10eb;
  margin-top: 25px;
  width: 100%;
  margin-right: 20px;
}

.datepicker-container {
  border-radius: 10px;
  height: 100px;
  /* text-align: center; */
  /* margin: 20px 0; */
  /* background-color: #4587e9; */
  background-color: #021a88;
  margin: 40px 70px 70px 70px;
  padding: 10px;
  display: block;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .news-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .news-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 722px) {
  .input {
    margin-top: 0;
  }
}

@media (max-width: 600px) {
  .news-container {
    grid-template-columns: 1fr;
  }
  .card {
    width: 100%;
  }
}
