.videoDetails-container {
  margin: 70px;
}

.videoDetails-header {
  display: flex;
}

.videoDetails-info {
  margin-left: 30px;
  align-content: center;
}

.videoDetails-date {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: #d81111;
}

.videoDetails-title {
  margin-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 42px;
  line-height: 51px;
  color: #4587e9;
  word-wrap: break-word;
}

.videoDetails-desc {
  font-family: sans-serif;
  font-size: 17px;
  line-height: 20px;
  margin-top: 30px;
}

.youTube-video {
  max-width: 560px;
  width: 100%;
  height: 315px;
  border-radius: 8px;
}

@media (max-width: 750px) {
  .videoDetails-header {
    display: block;
  }

  .videoDetails-info {
    margin-left: 0;
  }
}
