.newsDetails-container {
    /* margin: 70px; */
    /* margin: 5vw;  */
    padding: 40px;
    /* max-width: 100%;  */
}

.newsDetails-header{
  display: flex;
}
.newsDetails-info{
  margin-left: 15px;
  align-content: center;
}

.headContent {
    display: flex;
    flex-direction: column; 
}


.detailsImg{
    max-width: 557px;
    width: 100%;
    height: 300px;
    /* margin-right: 50px; */
    object-fit: cover;
    border-radius: 10px;
    background: 50% no-repeat;
    filter: brightness(.8);
    /* box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, .2); */
    cursor: pointer;
}
.mainImage {
  max-width: 557px;
  width: 100%;
  max-height: 400px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.detailsTitle{
    margin-top: 10px;
    /* margin-left: -83px; */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 42px;
    line-height: 51px;
    color: #4587e9;
    word-wrap: break-word;
}

.dateDetail{
font-weight: bold;
font-family: Arial, Helvetica, sans-serif;
color: #d81111;
}

.content {
    font-family: sans-serif;
    /* margin-top: 0; */
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 30px;
}

.dateH1{
    display: flex;
    margin-top: 35px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 58%;
    max-height: 85%;
  }

  .modal-img {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
  }
  
  .close {
    position: absolute;
    top: -4px;
    right: 6px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  @media (max-width: 750px) {
    .newsDetails-header{
      display: block;
    }
  }

  @media (min-width: 1500px) {
     .modal-img{
      max-height: 46rem;
      }
  }