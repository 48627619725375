.main-container{
 padding: 30px;
 max-width: 100%;
 text-align: center;
 box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
 background-color: aliceblue;
}

.partners-container {
    padding: 30px;
    background-color: transparent;
    border-radius: 15px;
    max-width: 100%;
    text-align: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    background-color: aliceblue;
  }

  .partners-logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .partner-link {
    display: inline-block; 
    transition: transform 0.3s;
  }
  
  .partner-link:hover {
    transform: scale(1.05);
  }
  
  .partner-logo {
     width: 150px;
    object-fit: contain; 
    box-sizing: border-box;
    border: none;
    display: block;
    min-width: 100%;
    max-width: 100%;
  }
  
.partners-title {
  background-color: #b5d1f7;
  margin-bottom: 3%;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
  color: #4587e9;
  width: max-content;
  max-width: 400px;
  border-radius: 6px;
  font-size: 24px;
  font-family: sans-serif;
}

.partners-title:hover {
  transform: translateY(-2px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}