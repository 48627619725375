.main-container {
  padding: 30px;
  max-width: 100%;
  text-align: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  background-color: aliceblue;
}

.titleEvents {
  margin-top: -5px;
  background-color: #b5d1f7;
  margin-bottom: 2%;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
  color: #4587e9;
  /* width: max-content; */
  max-width: 400px;
  border-radius: 6px;
  font-size: 24px;
  font-family: sans-serif;
  text-decoration: none;
}
.titleEvents:hover {
  transform: translateY(-2px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.cardUpEvents {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 500px;
  width: 100%;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.cardUpEvents:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.cardUpEvents:hover .image {
  opacity: 0.9;
}

.eventsTitle {
  font-size: 18px;
  font-weight: bold;
  color: #4587e9;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dateEvent {
  margin: 0;
  font-weight: bold;
  color: #d81111;
  font-size: 14px;
}

.dateEvent span {
  color: #021a88;
  margin-right: 10px;
  justify-content: space-around;
}

.buttonUpEvents {
  border-radius: 5px;
  margin-top: 10px;
  border: none;
  color: white;
  width: 150px;
  height: 30px;
  font-size: 14px;
  background: #d81111;
  display: inline-block;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out;
  color: white;
  cursor: pointer;
  bottom: 0;
}
.buttonUpEvents:hover {
  background-color: #4587e9;
}
/* .ant-card {
  width: 400px;
} */

@media (max-width: 1200px) {
  .dateEvent {
    font-size: 14px;
  }
}

@media (max-width: 722px) {
  .dateEvent {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .card {
    width: 100%;
  }
  .dateEvent {
    font-size: 14px;
  }
}
