.eventsDetails-container {
  margin: 60px;
}

.event-images {
  /* display: flex;
  gap: 10px;
  margin-bottom: 40px; */
  margin-bottom: 20px;
}

.event-image {
  width: 100%;
  object-fit: cover;
  max-width: 557px;
  height: 350px;
  border-radius: 30px;
  background: 50% no-repeat;
  filter: brightness(0.8);
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.eventsDetTitle {
  margin-top: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 42px;
  line-height: 51px;
  color: #4587e9;
}

.dateEventDet {
  /* margin: 0; */
  font-weight: bold;
  color: #d81111;
  font-size: 14px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.dateEventDet span {
  color: #021a88;
  margin-right: 10px;
  justify-content: space-around;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.eventContent {
  font-family: Arial, Helvetica, sans-serif;
  /* margin-top: 0; */
  font-size: 16px;
  line-height: 20px;
}

.eventContent span {
  font-weight: bold;
  color: #021a88;
  margin-right: 10px;
  justify-content: space-around;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
} */

.modal-content {
  /* position: relative;
  max-width: 90%;
  max-height: 90%; */
}

/* .modal-image {
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
}

.close-modal {
  position: absolute;
  top: -0.5%;
  right: 1%;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  z-index: 9999;
} */

/* @media (min-width: 1500px) {
  .modal-image{
   max-height: 46rem;
   }
} */

.file-button {
  width: 100px;
  background-color: #d81111;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-between;
  margin-right: 10px;
}

.file-button:hover {
  background-color: #0056b3;
}

.modal-content {
  position: relative;
  max-width: 60%;
  width: auto; /* Set to auto for width adjustment */
  height: auto; /* Set height to auto */
  max-height: 90%; /* Restrict height */
  overflow: hidden;
}

.modal-image {
  width: auto; /* Remove fixed width */
  height: auto; /* Maintain the aspect ratio */
  max-height: 85vh; /* Adjust height to fit within the viewport */
  border-radius: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  z-index: 9999;
}

@media (min-width: 1500px) {
  .modal-image {
    max-height: 60vh; /* Adjust for larger screens */
  }
}

.swiper-slide-image {
  width: 400px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (min-width: 1500px) {
  .swiper-slide-image {
    height: 600px; /* Adjust height for larger screens */
  }
}
