.video-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 70px;
  text-align: center;
  gap: 20px;
  min-height: 450px;
}
.video-card {
  margin-bottom: 40px;
  height: 500px;
  width: 100%;
  /* max-width: 557px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.video-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: opacity 0.3s;
}

.video-title {
  font-size: 18px;
  font-weight: bold;
  color: #4587e9;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  -webkit-text-stroke: 0.6px #7c7c7c;
  margin: 0;
}

.video-date {
  margin: 0;
  font-weight: bold;
  color: #d81111;
  font-size: 14px;
}

@media (max-width: 1200px) {
  .video-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .video-date {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .video-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 722px) {
  .video-date {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .video-container {
    grid-template-columns: 1fr;
  }
  .video-card {
    width: 100%;
  }
  .video-date {
    font-size: 14px;
  }
}
