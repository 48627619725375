.historyContainer {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 20px auto;
  font-family: "Arial", sans-serif;
}

.historyContainer strong {
  font-size: 1.5rem;
  color: #333;
  display: block;
  margin-bottom: 20px;
}

.historyContainer p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  border: 2px dashed #f71a1a;
  border-radius: 10px;
  padding: 15px;
}

.historyContainer p:nth-child(2) {
  border: none;
}

.kawaishiSection {
  display: flex;
}
.contentSection {
  display: flex;
  align-items: flex-start;
}
.imageSection {
  text-align: center;
  margin-right: 20px;
}

.judoImage {
  max-width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.textSection {
  flex: 1;
}

.personName {
  font-size: 1.2rem;
  color: #555;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 650px) {
  .contentSection {
    display: block;
  }
  .kawaishiSection {
    display: block;
  }
}
