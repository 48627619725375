.navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b10303;
  height: 80px;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  font-family: sans-serif;
}

.news__head_navbar {
  height: 100%;
}

.dropdown-menu {
  top: 100%;
  display: none;
  position: absolute;
  background-color: #fff;
  left: 0;
  color: #000;
  list-style: none;
  padding: 10px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  margin: 0;
  z-index: 1000;
}

.dropdown-menu div {
  margin: 5px 0;
  padding: 10px;
}

.dropdown-menu div:hover {
  /* background-color: darkgray; */
  border-color: #b10303;
  border-left: 0.4rem solid #b10303;
  color: var(--main-color);
}

.dropdown-menu div a {
  display: block;
  color: #000;
  border-bottom: 0.1rem solid #b10303;
  /* color: var(--black); */
  cursor: pointer;
  /* padding: 1rem; */
}

.dropdown-menu div a:hover {
  background-color: #f0f0f0;
}
.navbar-logo {
  margin-left: -20px;
}
.navbar-logo img {
  height: 60px;
  width: auto;
  object-fit: cover;
  margin-left: 70px;
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transform: rotate(45deg);
  vertical-align: middle;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  z-index: 100;
  align-items: center;
  height: 100%;
}

.navbar-item {
  margin: 0 15px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.navbar-item button {
  height: 100%;
  overflow: hidden;
  background-color: inherit;
  border: none;
  outline: none;
  font-size: 16px;
}

.navbar-item a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.navbar-item a:hover {
  color: black;
}

.navbar-item.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-icons {
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-left: 15px;
  color: white;
  font-size: 20px;
  display: none;
}

.navbar-icon:hover {
  color: #ccc;
}

.menu-icon {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  margin-left: auto;
}

@media screen and (max-width: 1176px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar-logo {
    margin-top: 5px;
  }

  .menu-icon {
    display: block;
  }

  .navbar-menu {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100vh;
    background-color: #ffffff;
    transition: right 0.3s ease-in-out;
    padding-top: 10px;
    margin-left: 15px;
  }
  .navbar-item.dropdown:hover .dropdown-menu {
    margin-top: 5px;
    display: block;
  }

  .navbar-item:hover {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }

  .navbar-menu.active {
    right: 0;
  }
  .arrow {
    border: solid #b10303;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
    transform: rotate(45deg);
    vertical-align: middle;
  }
  .navbar-item {
    margin: 10px 0;
    height: auto;
  }
  .navbar-item a {
    text-decoration: none;
    color: #b10303;
    font-weight: bold;
    margin-left: 10px;
    font-size: 20px;
  }
  .navbar-icons {
    margin-top: auto;
  }
  .navbar-item.dropdown .dropdown-menu {
    position: relative;
    top: 0;
    left: 0;
  }

  .news__head_navbar {
    height: auto;
  }

  .navbar-menu {
    display: flex;
  }
}
