.mainContainer {
  margin: 70px;
}

.usersListSection {
  margin-top: 30px;
  border-top: 1px solid #4587e9;
  padding-top: 30px;
}

.usersList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* margin: 70px; */
  text-align: center;
  gap: 20px;
}

.topUsersImg {
  width: 100%;
  height: 350px;
  border-bottom: 3px solid red;
  object-fit: cover;
}

.usersIamge {
  width: 100%;
  height: 350px;
  border-bottom: 3px solid red;
  object-fit: cover;
  border-radius: 8px;
}

.usersContent {
  text-align: center;
  height: auto;
  justify-content: flex-end;
  left: 0;
  /* padding: 1rem; */
  position: absolute;
  bottom: 7px;
  width: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

}


@media (max-width: 1200px) {
  .usersList {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 900px) {
  .usersList {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media (max-width: 722px) {
  .input {
    margin-top: 0;
  }
} */

@media (max-width: 600px) {
  .usersList {
    grid-template-columns: 2fr;
  }
}

.footer{
  position: relative !important;
  bottom: 0;
}