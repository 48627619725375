.headContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  margin: 20px;
  padding-top: 15px;
  text-align: center;
  align-items: center;

}

.head {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
}
.headLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headTitle {
  margin-top: -15px;
  background-color: #b5d1f7;
  margin-bottom: 2%;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  color: #4587e9;
  width: -moz-max-content;
  max-width: 400px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 24px;
  font-family: sans-serif;
}
.headTitle:hover {
  transform: translateY(-2px);

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.sendBtn {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.sentButton {
  background-color: #d81111;
  margin-top: 30px;
  height: 40px;
  align-items: center;
}

.sentButton:hover {
  color: #007bff;
}

.headInfo {
  margin-top: 20px;
  font-weight: bold;
  font-family: sans-serif;
  text-align: center;
}

.headInfo p {
  margin: 5px 0;
  color: #555;
  line-height: 1.6;
}

.headImg {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 300px;
  max-height: 330px;
  object-fit: cover;
  border-radius: 20px;
}

.sportAchievements {
  font-size: 16px;
  color: #333;
  line-height: 1.8;
  font-family: sans-serif;
}

.sportAchievements strong {
  color: #000;
  font-size: 18px;
}

span.sportAchievements {
  flex: 1;
  font-size: 16px;
  color: #333;
  line-height: 1.8;
  text-align: left;
  background-color: #e7f3ff;
  border: 1px solid #007bff;
  padding: 10px;
  border-radius: 5px;
  font-family: sans-serif;
}

form {
  padding: 50px;
  display: flex;
  flex-direction: column;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  position: relative;
}

.closeModalBtn {
  position: absolute;
  top: 10px;
  right: 0px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: black;
}

.openModalBtn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}

@media (max-width: 800px) {
  .head {
    display: grid;
  }
}
